<template>

  <div class="row">
    <div class="col-md-3">
      <div class="card mt-3">
        <div class="card-header"><h4>PDF</h4></div>
          <div class="card-body">
            <div class="d-flex">
              <input type="number" v-model="num_new_codes" class="form-control" style="width: 80px;"/>
              <button class="d-block btn btn-primary" @click="generujSet" v-if="!isLoading"> generate</button>
              <img src="/img/indicator.gif" v-if="isLoading" class="ml-auto mr-auto d-block"/>
            </div>

            <hr>
            <table class="table-bordered table table-condensed">
              <tr v-for="pdf in pdfs" :key="pdf.id" @click="filterSheets(pdf.id)" :class="licence_sheet_id ==pdf.id ? 'active':''">
                <td class="cursor-pointer">{{pdf.licences_count}}</td>
                <td class="cursor-pointer">{{pdf.created_at | date}}</td>
                <td><a :href="pdf.pdf_url" target="_blank"><img src="/img/pdf1.png" style="width: 30px;"/></a></td>


              </tr>
            </table>
          </div>
      </div>
    </div>
    <div class="col-auto">







      <div class="card mt-3">
        <div class="card-header">
          <h3>Licenční kody</h3>
        </div>
        <div class="card-body">
          <table class="table table-striped">
            <thead>
            <tr>
              <th>ID</th>
              <th></th>
              <th>Region</th>
              <th>Status</th>
              <th>Register at</th>
              <th>verze firmwaru</th>
              <th>Aktivní</th>
              <th></th>
            </tr>
            </thead>
            <tbody :key="tbodyk">
            <tr v-for="l in licences" :key="l.id">
              <td>
                #{{l.id}}
              </td>
              <td>{{l.kod}}</td>
              <td>{{l.region}}</td>
              <td>{{$t(`licence.status.${l.status}`)}}</td>
              <td>{{l.register_at | dateTime}}</td>
              <td>{{l.version}}</td>
              <td><span v-if="l.active" class="badge badge-success">ano</span><span v-else class="badge badge-danger">ne</span></td>
              <td>
                <a href="#" v-if="l.active" class="btn btn-outline-secondary" @click.prevent="setActive(l,0)">zakazat</a>
                <a href="#" v-else class="btn btn-outline-dark" @click.prevent="setActive(l,1)">povolit</a></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

import axiosApiInstance from "@/utils/api";
import userMixin from "@/mixins/userMixin";

export default {
  name: "Licence",
  mixins: [userMixin],
  data(){
    return{
      licences:[],
      tbodyk:"aaa",
      num_new_codes:10,
      pdfs:[],
      isLoading:false,
      licence_sheet_id: 0

    }
  },
  filters:{

  },

  methods:{

    generujSet(){
      this.isLoading=true
      const config={
        headers: {
          'Authorization': `Bearer ${this.token}`
        },
        params:{
          licences_count: this.num_new_codes
        }
      }
      axiosApiInstance.get('/licences/generatesheet', config).then(response=>{
        if (response.status == 200){
          this.loadLicences()
          this.loadSheets()
          this.isLoading=false
        }
      }).catch(err =>{
        alert('nastala chyba '+err)
        this.isLoading=false
      })

    },

    filterSheets(pdf_id){
      this.licence_sheet_id = pdf_id
      this.loadLicences()
    },

    loadLicences(){

      var config={
        headers: {
          'Authorization': `Bearer ${this.token}`
        },
        params:{

        }
      }
      if (this.licence_sheet_id >0) config.params.licence_sheet_id = this.licence_sheet_id

      axiosApiInstance.get('/licences/index', config).then(response=>{
        this.licences = response.data.licences
      })
    },
    loadSheets(){
      axiosApiInstance.get('/licences/sheets').then(response=>{
        this.pdfs = response.data.sheets
      })


    },
    setActive(licence, stav){
      const data = {
        id: licence.id,
        active: stav
      }
      const config={
        headers: {
          'Authorization': `Bearer ${this.token}`
        }
      }
      axiosApiInstance.put(`/licences/update/${licence.id}`, data, config).then(response=>{
        if (response.status == 200){
          for(let i in this.licences){
            if (this.licences[i].id == response.data.licence.id){
              this.licences[i] = response.data.licence
            }
          }
          this.tbodyk+="bs"
        }
      })
    }

  },
  mounted() {
    this.loadLicences()
    this.loadSheets()
  }


}
</script>

<style scoped>
tr.active{
  background: #ffffe0
}
</style>
